










import { gql } from 'graphql-tag';
import { Component, mixins } from 'nuxt-property-decorator';
import type { Ref } from '@nuxtjs/composition-api';

import { useHeaderColor } from '@/composables/header-color';

import { PageBlockMixin } from '@/modules/cms';
import { ScenkonstDigitalStageBlockDataFragment } from '@/graphql/generated';
import { digitalStageImageFragment } from '@/graphql/queries/image.fragments';

@Component({
  graphqlData: gql`
    fragment ScenkonstDigitalStageBlockData on ScenkonstDigitalStageBlock {
      id
      title
      body
      linkText
      linkUrl
      image {
        ...DigitalStageImageData
      }
    }

    ${digitalStageImageFragment}
  `,
  setup: () => useHeaderColor(),
})
export default class ScenkonstDigitalStageBlock extends mixins(PageBlockMixin<ScenkonstDigitalStageBlockDataFragment>()) {
  el: Ref<HTMLDivElement>

  headerColor: string
  headerColorInverted: string

  mounted () {
    if (this.$el === this.$el.parentNode.firstChild && this.data.image) {
      this.headerColor = '#FFF';
      this.headerColorInverted = '#000';
    }
  }
}
